.projects {
  display: grid;
  grid-auto-rows: auto;
  gap: 2.5rem;

  &__item {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      margin-right: 4rem;
    }

    &--background--ligth {
      padding: 3.2rem;
      background-color: $background_white_color--third;
      border-radius: 1.2rem;
    }

    &:hover {
      .projects__item__img {
        filter: brightness(0.5);
      }
    }

    &__img {
      width: 50%;
      height: 100%;
      transition: 0.2s ease-in-out;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1.2rem;
      }
    }

    &__text {
      width: 50%;
      display: flex;
      flex-direction: column;

      span {
        font-size: 1.6rem;
        color: $brand_darker_color;
      }

      p {
        font-size: 4rem;
        margin-top: 1.6rem;
        font-family: $font_medium;

        & + p {
          font-size: 1.6rem;
          margin-top: 2rem;
          line-height: 2.2rem;
          font-family: $font_light;
        }
      }
    }
  }

  &--about {
    margin-top: 5rem;

    .projects__item {
      &:hover {
        .projects__item__img {
          filter: none;
        }
      }

      &__img {
        width: 60%;
      }

      &__text {
        width: 40%;
        & p:first-child {
          margin-top: 0;
          margin-bottom: 1rem;
          line-height: 4.8rem;
        }
      }
    }
  }
}

.project {
  .img__hover__transform {
    height: 28rem;
  }

  &__photos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    &__img {
      display: flex;
      width: 100%;
      &--one {
        height: 22.5rem;
      }
      &--two {
        height: 29.25rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      &--three {
        height: 20.1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
      }
    }
  }
}