.nav__dropdown {
  float: left;
  overflow: hidden;
  &.nav__dropdown--right {
    float: right;
    .nav__dropdown__list {
      right: 3rem;
      a {
        line-height: 1.9rem;
      }
    }
  }
  &__list {
    display: none;
    grid-template-columns: auto;
    grid-gap: 0.8rem;
    position: absolute;
    background-color: $white_color;
    box-shadow: $dropdown_shadow;
    width: 24.9rem;
    padding: 1.2rem;
    border-radius: 1.2rem;
    z-index: 1;
  }

  &__item {
    background-color: $dropdown_background_color;
    border-radius: 1rem;
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    min-height: 9.75rem;
    max-width: 22.5rem;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 9.75rem;

      img {
        max-width: 16.75rem;
      }

      & > span {
        font-size: $nav_font_size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          font-size: 1rem;
          margin-top: 1rem;
          color: $brand_darker_color;
        }
      }
    }
  }

  &:hover {
    .nav__dropdown__list {
      display: grid;
    }
  }
}
