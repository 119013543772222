/* свойства модального окна по умолчанию */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  -webkit-transition: opacity 0 ease-in;
  -moz-transition: opacity 0 ease-in;
  transition: opacity 0 ease-in;
  pointer-events: none;
  margin: 0;
  padding: 0;
  display: none;
  pointer-events: auto;
  overflow-y: auto;

  &__dialog {
    position: relative;
    width: 53.5rem;
    margin: 2rem auto;
  }
  &__content {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: $body_background_color;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 3rem;
    outline: 0;
    padding: 2.5rem;
  }
  &__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
  }
  .close {
    float: right;
    width: 1.6rem;
    height: 1.6rem;
    opacity: 0.5;
    text-decoration: none;
    position: absolute;
    right: 0;
    z-index: 10;
    &:focus,
    &:hover {
      opacity: 0.75;
    }
  }
  &__logo {
    width: 5.35rem;
    margin: 3rem auto 2rem;
  }
  &__body {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: auto;

    & > p {
      margin-bottom: 2rem;
      line-height: 3.5rem;
      font-size: 2.5rem;
      font-family: $font_bold;
      text-align: center;
    }

    .form__group {
      width: 30rem;
      margin: 3rem auto 5.5rem;

      &--100 {
        width: 100%;
        margin: 0 0 2rem;
      }

      &__title {
        font-family: $font_bold;
        font-size: 1.2rem;
        color: $brand_color;
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }

    .form__group--100.form__group__btn {
      width: 100%;
      margin: 0 auto;

      .main__btn {
        margin: 0;
      }
    }

    .form__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      &__col {
        .radio {
          label {
            width: 45%;
          }
        }
      }
    }

    .radio {
      label {
        width: auto;
        min-width: 11rem;
      }
    }
  }
}
.modal-overlay {
	filter: alpha(opacity=0);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3) !important;
}