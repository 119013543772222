nav {
  display: grid;
  grid-template-columns: 5.35rem auto 1fr;
  align-items: center;
  margin-top: 0.9rem;

  .nav {
    display: flex;
    align-items: center;
    &--main {
      margin-left: 0.85rem;
    }
    &--second {
      justify-self: end;
      align-self: center;
    }
    &__item {
      display: block;
      padding: 0.35rem 1.4rem 0;

      &:last-child {
        padding-right: 0;
      }
      a,
      button {
        font-size: $nav_font_size;
        line-height: 2.7rem;
        color: $brand_color;
        white-space: nowrap;
      }

      &:hover {
        & > a {
          text-decoration: underline;
        }
      }
      &--dropdown:hover,
      &--icon:hover,
      &--selected:hover {
        a {
          text-decoration: none;
        }
      }

      &--icon {
        a {
          position: relative;
          padding-left: 2.2rem;
          display: flex;
          align-items: center;
          &::before {
            display: block;
            position: absolute;
            content: '';
            left: 0;
            top: 0.5rem;
            background-size: contain;
            background-repeat: no-repeat;
          }

          &.nav__item__link--download {
            &::before {
              width: 1.4rem;
              height: 1.4rem;
              background-image: url('../img/icons/download.svg');
            }
          }
        }
      }
    }
  }
}
