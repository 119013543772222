.guide {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(0, min(100%/3, max(16.5rem, 100%/3)))
  );

  &__item {
    display: flex;
    flex-direction: column;
    max-width: 16.5rem;
    margin: 0 auto;
    &__img {
      border-radius: 1.2rem;
      width: 16.5rem;
      height: 16.5rem;
      margin-bottom: 1.6rem;

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 1.2rem;
      }
    }
    &__text {
      p {
        font-size: 1.5rem;
        margin-bottom: 1rem;

        span {
          color: $brand_darker_color;
        }
      }
      &__name {
        margin-top: 1.6rem;
        font-size: 2.25rem;
        margin-bottom: 3rem;
        font-family: $font_medium;
      }
    }
  }
}

.guide__info {
  width: 60rem;
  margin: 3rem auto;
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;

    span {
      text-align: center;
      font-size: 1.6rem;
      color: $brand_darker_color;
    }

    h2 {
      font-size: 4rem;
      line-height: 4.8rem;
      text-align: center;
      margin: 0;
      text-transform: inherit;
    }
  }
  &__text {
    p {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }

    ul {
      border-radius: 1.2rem;
      padding: 3rem;
      background-color: $background_white_color--third;
      list-style-type: disc;
      margin-bottom: 3rem;
      li {
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
      }
    }

    .image {
      border-radius: 1.2rem;
      margin-bottom: 3rem;
      width: 100%;
      object-fit: cover;
    }

    h3,
    h4 {
      margin-bottom: 3rem;
    }
  }
  &--row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    gap: 1rem;
    margin-bottom: 3rem;

    .image {
      margin-bottom: 0;
    }
  }
  span {
    font-size: 1.6rem;
    color: $brand_darker_color;
    margin-bottom: 3rem;
    display: block;
  }
  .video {
    margin-bottom: 2rem;
  }
  .products {
    &--fill {
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    }
  }
}
