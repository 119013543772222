.ticker {
  position: relative;
  overflow: hidden;
  padding: 4rem 0 3.5rem 0;
  border: 1px solid $brand_darker_color_opacity;

  border-left: 0;
  border-right: 0;
  &__text {
    line-height: 7.55rem;
    font-size: 6.3rem;
    text-align: center;
    text-transform: uppercase;
    padding-left: 100%;
    white-space: nowrap;
    transform: translateX(-250%);
    animation: text 6s linear infinite;
    animation-direction: reverse;
  }
  @keyframes text {
    100% {
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
    }
  }
}
