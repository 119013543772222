.about__us {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 32rem;
  gap: 3rem;
  p {
    font-size: 4rem;
    line-height: 4.7rem;
    font-family: $font_medium;
    text-transform: inherit;
  }

  span {
    color: $brand_darker_color;
    font-size: 1.6rem;
    display: block;
    width: 50%;
    margin-right: 0;
    margin-left: auto;
  }

  .video {
    display: block;

    &__item {
      height: 100%;
    }
  }
}

.about__team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 21rem auto auto;
    gap: 0.2rem;
    justify-self: center;

    &__img {
      width: 21rem;
      height: 21rem;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    p {
      text-align: center;
      font-size: 2rem;
      font-family: $font_medium;
    }
    span {
      display: block;
      text-align: center;
      font-size: 1.3rem;
      color: $brand_darker_color;
      margin-bottom: 1.6rem;
    }
  }
}
