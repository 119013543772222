.banner {
  min-height: calc(90vh - 5.35rem);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 9rem;

  &__text {
    font-size: 17.5rem;
    line-height: 25.45rem;
    text-align: center;
  }

  &__logo {
    opacity: 0.5;
    width: 5.35rem;
    margin: 0 auto;

    &--auto {
      margin: auto;
      width: 5.35rem;
    }
  }

  &--medium {
    height: auto;
    min-height: auto;
    grid-auto-rows: auto;
    grid-template-rows: auto;

    .banner__text {
      font-size: 10rem;
      line-height: 14.15rem;
      text-align: center;
      margin-top: 9rem;
    }

    p {
      font-size: 2.25rem;
      line-height: 3.15rem;
      display: block;
      text-align: center;
      border-radius: 1.2rem;
      padding: 3.6rem;
      background-color: $background_white_color--third;
      margin-top: 9rem;
    }
  }
}

.product__banner {
  position: relative;
  img {
    display: block;
    position: relative;
    z-index: 2;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 7.8rem;
    height: 100%;
    &__top {
      position: absolute;
      top: 0.8rem;
      margin: 0 10.6rem;
      z-index: 1;
      p {
        font-size: 12.25rem;
        line-height: 10rem;
        &:last-child {
          margin-left: 30rem;
        }
      }
    }

    &__bottom {
      margin: 0 10.6rem;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 26%;
      z-index: 3;
      p {
        font-size: 12.25rem;
        line-height: 10rem;
        font-family: $font_italic;
        &.text__small {
          font-size: 5rem;
          line-height: 4rem;
          font-family: $font_light;
        }
      }
    }

    .text__row {
      display: flex;
    }
    .text__opacity {
      position: relative;
      p {
        left: 3rem;
        top: 0;
        position: absolute;
        &:first-child {
          opacity: 0.2;
        }
      }
    }
  }
}

.project__banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: auto;
  padding-top: 15rem;
  padding-bottom: 6rem;
  background-color: $background_white_color--third;
  margin-top: -9rem;
  margin-bottom: 4rem;

  span {
    width: 50%;
  }

  &__text {
    font-size: 6.3rem;
    font-weight: 500;
  }

  &__small__text{
    font-size: 1.6rem;
    line-height: 2.24rem;
  }
}