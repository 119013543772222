.effects__list {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(0, min(100%/2, max(16.5rem, 100%/3)))
  );

  .item {
    padding: 0.5rem;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;

    &__img {
      img {
        border-radius: 1.2rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        height: 32rem;
        margin-bottom: 1.6rem;
      }
    }

    &__text {
      span {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: $brand_darker_color;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-transform: uppercase;
        font-family: $font_medium;
      }
    }
  }
}
