.calculator {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  border-radius: 1.2rem;

 &--carousel {
    grid-template-columns: 0.7fr 1.3fr;

    .calculator__form {
      padding: 1.6rem 2rem 2.5rem;
    }
  }

  &_img {
    background-color: $background_white_color--second;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    padding: 1.6rem 4rem;
    display: flex;
    flex-direction: column;
    background-color: $background_white_color--third;
   
    & > h3 {
      margin-bottom: 0.2rem;
    }
    & > img {
      margin-bottom: 1.6rem;
    }
    & > span {
      font-size: 1.3rem;
      line-height: 2.25rem;
      color: $brand_darker_color;
      font-family: $font_medium;
    }

    &--gallery {
      align-items: flex-start;
      justify-content: center;

      span {
        padding: 1rem 0 2rem;
        font-size: 1.6rem;
        font-family: $font_light;
      }
    }

    form {
      margin-top: 2rem;
      position: relative;

      h5 {
        margin-bottom: 1.4rem;
      }
    }

    &__radio {
      &.radio {
        flex-wrap: nowrap;
      }
      &__item {
        border-radius: 1.2rem;
        height: 17.45rem;
        background-color: $white_color;
      }

      img {
        width: 100%;
        height: 100%;
        height: 14.2rem;
        border-radius: 1.2rem;
      }

      &__item--small {
        height: 4.1rem;
        label {
          height: 4.1rem;
          display: block;
          width: 10rem;
        }

        [type='radio']:checked + span,
        [type='radio']:not(:checked) + span {
          padding-left: 2rem;
          height: 4.1rem;
          margin-top: 0;
          margin-left: 0;
          border-radius: 1.2rem;
          border: 1px solid $white_color;
        }

        [type='radio']:not(:checked) + span {
          border-color: $white_color;
        }
        [type='radio']:checked + span {
          border-color: $brand_color;
        }

        [type='radio']:checked + span span:before,
        [type='radio']:not(:checked) + span span:before {
          display: none;
        }
        [type='radio']:checked + span span:after,
        [type='radio']:not(:checked) + span span:after {
          display: none;
        }

        [type='radio'] + span .circle {
          width: 1.7rem;
          height: 1.7rem;
          border-radius: 100%;
          border: 1px solid $light_color;
          position: relative;
          left: -1rem;

          &--black {
            background-color: $brand_color;
          }
        }
      }
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.2rem;
    }

    &__detail {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &__group {
        display: flex;
        align-items: center;
        min-width: 13rem;
        margin-bottom: 2rem;

        &--total {
          position: absolute;
          left: 0;
          bottom: -1.4rem;
        }

        & span {
          color: $brand_darker_color;
          font-family: $font_medium;
          font-size: 1.6rem;
          display: flex;
          align-items: center;

          span {
            color: $brand_color;
            margin-left: 1.2rem;
          }
        }
      }
    }
  }
}

.quantity {
  &__minus,
  &__plus {
    display: flex;

    svg {
      width: 1rem;
    }
  }
}
