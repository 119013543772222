@charset "UTF-8";
@font-face {
  font-family: "HelveticaNeueLight";
  src: url("../fonts/HelveticaNeueLight.ttf") format("ttf"), url("../fonts/HelveticaNeueLight.woff") format("woff");
}
@font-face {
  font-family: "HelveticaNeueMedium";
  src: url("../fonts/HelveticaNeueMedium.ttf") format("ttf"), url("../fonts/HelveticaNeueMedium.woff") format("woff");
}
@font-face {
  font-family: "HelveticaNeuBold";
  src: url("../fonts/HelveticaNeuBold.ttf") format("ttf"), url("../fonts/HelveticaNeueBold.woff") format("woff");
}
@font-face {
  font-family: "TimesNewRomanItalic";
  src: url("../fonts/TimesNewRomanItalic.ttf") format("ttf"), url("../fonts/TimesNewRomanItalic.woff") format("woff");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #131313;
}

html {
  font-size: 20px;
  line-height: 1.5;
}

body {
  font-family: HelveticaNeueLight;
  background-color: #fff;
  color: #131313;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea {
  outline: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

.font-medium {
  font-family: HelveticaNeueMedium !important;
}

.font-light {
  font-family: HelveticaNeueLight !important;
}

h1 {
  font-size: 10rem;
  line-height: 9.6rem;
  color: #fff;
  font-weight: 400;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.65rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0 3rem;
  margin-top: 9rem;
}
h2.h2__big {
  text-transform: capitalize;
  font-size: 6.3rem;
  line-height: 7.55rem;
  margin: 5rem auto;
  font-family: HelveticaNeueMedium;
}
h2 + hr {
  margin-top: 1.2rem;
  margin-bottom: 4rem;
}

h3 {
  font-size: 2.15rem;
  line-height: 2.65rem;
  font-family: HelveticaNeuBold;
}

h4 {
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-family: HelveticaNeueMedium;
  margin-bottom: 1.2rem;
  font-weight: 500;
}

h5 {
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-family: HelveticaNeueLight;
  font-weight: 400;
}

.p_0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.main__padding {
  padding: 0 3rem;
}

main {
  margin-top: 7.35rem;
}

hr {
  opacity: 0.6;
  border: 0;
  border-color: #a3a3a3;
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  margin: 4rem 0;
}

.main__btn {
  margin: 4rem auto 6rem;
  max-width: 90rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: HelveticaNeuBold;
  font-size: 1.8rem;
  line-height: 2rem;
  color: #131313;
  background-color: #fff;
  border: 1px solid #131313;
  border-radius: 500rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
}
.main__btn:hover {
  color: #fff;
  background-color: #131313;
  border-color: #131313;
}
.main__btn--dark {
  color: #fff;
  background-color: #131313;
}
.main__btn--dark:hover {
  color: #131313;
  background-color: #fff;
}
.main__btn--arrow {
  padding-right: 3.9rem;
}
.main__btn--arrow::after {
  position: relative;
  content: "";
  background-image: url(../img/icons/arrow-right.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 1rem;
  height: 1rem;
  right: -0.9rem;
  filter: none;
}
.main__btn--arrow:hover::after {
  filter: brightness(0) invert(1);
}

.main__small_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: HelveticaNeueMedium;
  font-size: 1.2rem;
  padding: 1.35rem 3.6rem;
  color: #fff;
  background-color: #131313;
  border: 1px solid #131313;
  border-radius: 500rem;
  transition-property: background-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  position: relative;
}
.main__small_btn--arrow {
  padding-right: 3.9rem;
}
.main__small_btn--arrow::after {
  position: relative;
  content: "";
  background-image: url(../img/icons/arrow-right.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 1rem;
  height: 1rem;
  right: -0.9rem;
  filter: brightness(0) invert(1);
}
.main__small_btn--arrow:hover::after {
  filter: none;
}
.main__small_btn:hover {
  color: #131313;
  background-color: #fff;
}
.main__small_btn--white {
  color: #131313;
  background-color: #fff;
  border-color: #fff;
}
.main__small_btn--white:hover {
  color: #fff;
  background-color: #131313;
  border-color: #fff;
}

.img__hover__transform {
  overflow: hidden;
  border-radius: 1.2rem;
  width: 100%;
  height: 100%;
}
.img__hover__transform img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease-in-out;
  border-radius: 1.2rem;
}
.img__hover__transform:hover img {
  transform: scale(1.04);
  border-radius: 1.2rem;
}

.line {
  width: 100%;
  padding: 2.2rem 0.75rem;
  margin: 6rem 0;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line__text {
  font-size: 1.6rem;
  color: #a3a3a3;
  font-family: HelveticaNeuBold;
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.radio {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.radio label {
  cursor: pointer;
}
.radio label span {
  display: block;
  font-size: 1.1rem;
}
.radio label input {
  cursor: none;
}
.radio [type=radio]:checked,
.radio [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
  cursor: none;
}
.radio [type=radio]:checked + span,
.radio [type=radio]:not(:checked) + span {
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #131313;
  height: 1.65rem;
  margin-top: 0.5rem;
  margin-left: 0.6rem;
}
.radio [type=radio]:checked + span span:before,
.radio [type=radio]:not(:checked) + span span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.1rem;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #131313;
  border-radius: 100%;
}
.radio [type=radio]:checked + span span:after,
.radio [type=radio]:not(:checked) + span span:after {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background: #131313;
  position: absolute;
  top: 0.41rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio [type=radio]:not(:checked) + span span:after {
  opacity: 0;
}
.radio [type=radio]:checked + span span:after {
  opacity: 1;
}
.radio [type=radio]:checked + .wpcf7-list-item-label:before,
.radio [type=radio]:not(:checked) + .wpcf7-list-item-label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.1rem;
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #131313;
  border-radius: 100%;
}
.radio [type=radio]:checked + .wpcf7-list-item-label:after,
.radio [type=radio]:not(:checked) + .wpcf7-list-item-label:after {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background: #131313;
  position: absolute;
  top: 0.41rem;
  left: 0.3rem;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio [type=radio]:not(:checked) + .wpcf7-list-item-label:after {
  opacity: 0;
}
.radio [type=radio]:checked + .wpcf7-list-item-label:after {
  opacity: 1;
}

.wpcf7-not-valid-tip {
  display: none !important;
}

.wpcf7 form .wpcf7-response-output {
  display: none !important;
}

.form__group__btn {
  position: relative;
}

form .wpcf7-spinner {
  position: absolute !important;
  top: 43%;
  right: 3%;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 1.25rem;
}
form .wpcf7-list-item {
  margin: 0;
}
form .wpcf7-radio {
  display: flex;
  gap: 1rem;
}

header {
  display: grid;
  align-items: center;
  height: 7.35rem;
  width: 100%;
  padding: 0 3rem;
  position: fixed;
  top: 0;
  z-index: 100;
}
header.header__fixed, header.active {
  background-color: #fff;
}
header.header__fixed nav, header.active nav {
  background-color: #fff;
}
header .hamburger {
  display: none;
  width: 4rem;
  padding-right: 1.5rem;
  margin-top: 0.3rem;
}
header.active {
  background: #fff;
  width: 100%;
}
header.active .nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #fff;
  top: 3rem;
  padding: 0;
  margin: 0;
  width: 100%;
  left: 0;
  right: 0;
}
header .logo {
  width: 5.35rem;
  display: block;
  margin-top: 0.2rem;
}
header .logo__picture {
  width: 5.35rem;
  display: block;
}
header .logo__picture img {
  width: 5.35rem;
}

nav {
  display: grid;
  grid-template-columns: 5.35rem auto 1fr;
  align-items: center;
  margin-top: 0.9rem;
}
nav .nav {
  display: flex;
  align-items: center;
}
nav .nav--main {
  margin-left: 0.85rem;
}
nav .nav--second {
  justify-self: end;
  align-self: center;
}
nav .nav__item {
  display: block;
  padding: 0.35rem 1.4rem 0;
}
nav .nav__item:last-child {
  padding-right: 0;
}
nav .nav__item a,
nav .nav__item button {
  font-size: 1.6rem;
  line-height: 2.7rem;
  color: #131313;
  white-space: nowrap;
}
nav .nav__item:hover > a {
  text-decoration: underline;
}
nav .nav__item--dropdown:hover a, nav .nav__item--icon:hover a, nav .nav__item--selected:hover a {
  text-decoration: none;
}
nav .nav__item--icon a {
  position: relative;
  padding-left: 2.2rem;
  display: flex;
  align-items: center;
}
nav .nav__item--icon a::before {
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 0.5rem;
  background-size: contain;
  background-repeat: no-repeat;
}
nav .nav__item--icon a.nav__item__link--download::before {
  width: 1.4rem;
  height: 1.4rem;
  background-image: url("../img/icons/download.svg");
}

.nav__dropdown {
  float: left;
  overflow: hidden;
}
.nav__dropdown.nav__dropdown--right {
  float: right;
}
.nav__dropdown.nav__dropdown--right .nav__dropdown__list {
  right: 3rem;
}
.nav__dropdown.nav__dropdown--right .nav__dropdown__list a {
  line-height: 1.9rem;
}
.nav__dropdown__list {
  display: none;
  grid-template-columns: auto;
  grid-gap: 0.8rem;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.2);
  width: 24.9rem;
  padding: 1.2rem;
  border-radius: 1.2rem;
  z-index: 1;
}
.nav__dropdown__item {
  background-color: #f4f4f4;
  border-radius: 1rem;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  min-height: 9.75rem;
  max-width: 22.5rem;
}
.nav__dropdown__item a {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 9.75rem;
}
.nav__dropdown__item a img {
  max-width: 16.75rem;
}
.nav__dropdown__item a > span {
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav__dropdown__item a > span span {
  font-size: 1rem;
  margin-top: 1rem;
  color: #a3a3a3;
}
.nav__dropdown:hover .nav__dropdown__list {
  display: grid;
}

.slider {
  height: 50.5rem;
  width: 100%;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}
.slider__img {
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}
.slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider__shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #131313;
  opacity: 0.65;
  top: 0;
  left: 0;
  border-radius: 1rem;
}
.slider__text {
  display: grid;
  height: 50.5rem;
  width: 100%;
  position: absolute;
  grid-template-rows: 1fr auto;
  top: 0;
  z-index: 1;
  padding-bottom: 4rem;
}
.slider__text--left {
  text-align: right;
}
.slider__text__italic {
  font-family: TimesNewRomanItalic;
  color: #fff;
}
.slider__text__top {
  margin: 5.7rem 1.5rem 0 8.5rem;
}
.slider__text__top h1 {
  display: flex;
  flex-direction: column;
}
.slider__text__top h1 > span {
  margin-left: 7.6rem;
  color: #fff;
}
.slider__text__top--gallery h1 > span {
  margin-left: 35rem;
}
.slider__text__bottom {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #fff;
  padding: 1.5rem 1.7rem;
  border: 1px solid #fff;
  width: 35.95rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider__text__bottom span {
  text-align: center;
  color: #fff;
}

.ticker {
  position: relative;
  overflow: hidden;
  padding: 4rem 0 3.5rem 0;
  border: 1px solid rgba(163, 163, 163, 0.6);
  border-left: 0;
  border-right: 0;
}
.ticker__text {
  line-height: 7.55rem;
  font-size: 6.3rem;
  text-align: center;
  text-transform: uppercase;
  padding-left: 100%;
  white-space: nowrap;
  transform: translateX(-250%);
  animation: text 6s linear infinite;
  animation-direction: reverse;
}
@keyframes text {
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.gallery {
  display: grid;
  grid-template: "big small_one" 1fr "big small_two" 1fr/6.7fr 5fr;
  gap: 1px;
}
.gallery__item {
  position: relative;
}
.gallery__item__img {
  width: 100%;
  height: 100%;
}
.gallery__item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery__item__text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  font-size: 2.25rem;
  color: #fff;
  font-family: HelveticaNeueMedium;
  transition: transform 0.4s ease-in-out;
}
.gallery__item__shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #131313;
  opacity: 0.35;
  top: 0;
  left: 0;
  transition: opacity 0.4s ease-in-out 0s;
}
.gallery__item:hover .gallery__item__shadow {
  opacity: 0.75;
}
.gallery__item:hover .gallery__item__text {
  transform: scale(1.3);
}
.gallery__item--big {
  background-color: lime;
  grid-area: big;
}
.gallery__item--one {
  background-color: red;
  grid-area: small_one;
}
.gallery__item--two {
  background-color: red;
  grid-area: small_two;
}

.products {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-bottom: 5rem;
}
.products__item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 1.2rem;
  overflow: hidden;
  position: relative;
  -webkit-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
}
.products__item__img {
  position: relative;
  transition: transform 0.4s ease-in-out 0s;
  margin-left: auto;
  width: 68%;
}
.products__item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.products__item:hover .products__item__img {
  transform: scale(1.1);
}
.products__item__text {
  background-color: #131313;
  color: #fff;
  font-size: 2.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.35rem;
  padding: 0 2rem;
  font-family: HelveticaNeueMedium;
}

.calculator_carousel {
  background-color: #f1f1f1;
}
.calculator_carousel__img {
  border-radius: 1.2rem;
  margin: 0.8rem;
}
.calculator_carousel__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.2rem;
}

.calculator {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  border-radius: 1.2rem;
}
.calculator--carousel {
  grid-template-columns: 0.7fr 1.3fr;
}
.calculator--carousel .calculator__form {
  padding: 1.6rem 2rem 2.5rem;
}
.calculator_img {
  background-color: #ededf3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calculator__form {
  padding: 1.6rem 4rem;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
}
.calculator__form > h3 {
  margin-bottom: 0.2rem;
}
.calculator__form > img {
  margin-bottom: 1.6rem;
}
.calculator__form > span {
  font-size: 1.3rem;
  line-height: 2.25rem;
  color: #a3a3a3;
  font-family: HelveticaNeueMedium;
}
.calculator__form--gallery {
  align-items: flex-start;
  justify-content: center;
}
.calculator__form--gallery span {
  padding: 1rem 0 2rem;
  font-size: 1.6rem;
  font-family: HelveticaNeueLight;
}
.calculator__form form {
  margin-top: 2rem;
  position: relative;
}
.calculator__form form h5 {
  margin-bottom: 1.4rem;
}
.calculator__form__radio.radio {
  flex-wrap: nowrap;
}
.calculator__form__radio__item {
  border-radius: 1.2rem;
  height: 17.45rem;
  background-color: #fff;
}
.calculator__form__radio img {
  width: 100%;
  height: 100%;
  height: 14.2rem;
  border-radius: 1.2rem;
}
.calculator__form__radio__item--small {
  height: 4.1rem;
}
.calculator__form__radio__item--small label {
  height: 4.1rem;
  display: block;
  width: 10rem;
}
.calculator__form__radio__item--small [type=radio]:checked + span,
.calculator__form__radio__item--small [type=radio]:not(:checked) + span {
  padding-left: 2rem;
  height: 4.1rem;
  margin-top: 0;
  margin-left: 0;
  border-radius: 1.2rem;
  border: 1px solid #fff;
}
.calculator__form__radio__item--small [type=radio]:not(:checked) + span {
  border-color: #fff;
}
.calculator__form__radio__item--small [type=radio]:checked + span {
  border-color: #131313;
}
.calculator__form__radio__item--small [type=radio]:checked + span span:before,
.calculator__form__radio__item--small [type=radio]:not(:checked) + span span:before {
  display: none;
}
.calculator__form__radio__item--small [type=radio]:checked + span span:after,
.calculator__form__radio__item--small [type=radio]:not(:checked) + span span:after {
  display: none;
}
.calculator__form__radio__item--small [type=radio] + span .circle {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 100%;
  border: 1px solid #e2e8f0;
  position: relative;
  left: -1rem;
}
.calculator__form__radio__item--small [type=radio] + span .circle--black {
  background-color: #131313;
}
.calculator__form__btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;
}
.calculator__form__detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.calculator__form__detail__group {
  display: flex;
  align-items: center;
  min-width: 13rem;
  margin-bottom: 2rem;
}
.calculator__form__detail__group--total {
  position: absolute;
  left: 0;
  bottom: -1.4rem;
}
.calculator__form__detail__group span {
  color: #a3a3a3;
  font-family: HelveticaNeueMedium;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}
.calculator__form__detail__group span span {
  color: #131313;
  margin-left: 1.2rem;
}

.quantity__minus, .quantity__plus {
  display: flex;
}
.quantity__minus svg, .quantity__plus svg {
  width: 1rem;
}

.projects {
  display: grid;
  grid-auto-rows: auto;
  gap: 2.5rem;
}
.projects__item {
  display: flex;
  justify-content: space-between;
}
.projects__item > div:first-child {
  margin-right: 4rem;
}
.projects__item--background--ligth {
  padding: 3.2rem;
  background-color: #f1f1f1;
  border-radius: 1.2rem;
}
.projects__item:hover .projects__item__img {
  filter: brightness(0.5);
}
.projects__item__img {
  width: 50%;
  height: 100%;
  transition: 0.2s ease-in-out;
}
.projects__item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.2rem;
}
.projects__item__text {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.projects__item__text span {
  font-size: 1.6rem;
  color: #a3a3a3;
}
.projects__item__text p {
  font-size: 4rem;
  margin-top: 1.6rem;
  font-family: HelveticaNeueMedium;
}
.projects__item__text p + p {
  font-size: 1.6rem;
  margin-top: 2rem;
  line-height: 2.2rem;
  font-family: HelveticaNeueLight;
}
.projects--about {
  margin-top: 5rem;
}
.projects--about .projects__item:hover .projects__item__img {
  filter: none;
}
.projects--about .projects__item__img {
  width: 60%;
}
.projects--about .projects__item__text {
  width: 40%;
}
.projects--about .projects__item__text p:first-child {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 4.8rem;
}

.project .img__hover__transform {
  height: 28rem;
}
.project__photos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.project__photos__img {
  display: flex;
  width: 100%;
}
.project__photos__img--one {
  height: 22.5rem;
}
.project__photos__img--two {
  height: 29.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.project__photos__img--three {
  height: 20.1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.steps {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}
.steps__item {
  display: grid;
  grid-gap: 2rem;
  grid-template: "text text" "leftImage rightImage";
  grid-template-rows: auto 29rem;
  grid-template-columns: 1fr 1fr;
}
.steps__item__text {
  grid-area: text;
  padding: 3rem;
  border-radius: 1.2rem;
  background-color: #f1f1f1;
}
.steps__item__text span {
  display: inline-block;
  text-align: right;
  width: 100%;
  font-size: 2.25rem;
  margin-bottom: 3.65rem;
  color: #9e9e9e;
}
.steps__item__text p {
  font-size: 2.25rem;
  line-height: 3.15rem;
}
.steps__item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.2rem;
}
.steps__item__image--left {
  grid-area: leftImage;
}
.steps__item__image--right {
  grid-area: rightImage;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  grid-template-rows: 34rem minmax(9rem, 34rem);
  margin-bottom: 9rem;
}

.about__founder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5rem;
}
.about__founder__img {
  max-height: 37rem;
}
.about__founder__text {
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  padding-top: 0.2rem;
}
.about__founder__text span {
  font-size: 1.6rem;
  color: #a3a3a3;
  margin-bottom: 1.2rem;
  display: block;
}
.about__founder__text p {
  color: #131313;
  font-size: 10rem;
  line-height: 12.5rem;
  margin-top: 2rem;
}
.about__quote {
  border-radius: 1.2rem;
  background-color: #f1f1f1;
  padding: 1rem;
}
.about__quote span {
  line-height: 5.5rem;
  font-size: 4rem;
  font-family: TimesNewRomanItalic;
}

.carousel {
  overflow: hidden;
  margin-bottom: 8rem;
}
.carousel .owl-stage {
  padding: 0 0 0 3rem !important;
}
.carousel .item {
  padding: 3rem 2rem 3rem 3rem;
  border-radius: 1.2rem;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
}
.carousel .item__img img {
  border-radius: 1.2rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 32rem;
}
.carousel .item__top {
  display: grid;
  grid-template-columns: 5rem 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}
.carousel .item__top__image {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  background-color: #f1f1f1;
}
.carousel .item__top__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.carousel .item__top__text__name {
  margin-bottom: 0.7rem;
  font-size: 2.2rem;
  line-height: 2.7rem;
  font-family: HelveticaNeueMedium;
}
.carousel .item__top__text__position {
  margin-bottom: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.carousel .item__top__text__data {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #a3a3a3;
}
.carousel .item__bottom {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.effects--text .item {
  background-color: #fff;
}
.effects--text .item__img img {
  margin-bottom: 1.6rem;
}
.effects--text .item__text span {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #a3a3a3;
}
.effects--text .item__text p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  font-family: HelveticaNeueMedium;
}

.video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 1rem;
}
.video--1fr {
  grid-template-columns: 1fr;
}
.video__item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.video__item__img {
  position: relative;
}
.video__item__text {
  margin-top: 1rem;
  font-size: 2.25rem;
  font-family: HelveticaNeueMedium;
}
.video__item__shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #131313;
  opacity: 0.5;
  top: 0;
  left: 0;
  border-radius: 1rem;
}

.play {
  width: 6rem;
  height: 6rem;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 3rem);
  top: calc(50% - 3rem);
  z-index: 10;
}
.play img {
  width: 30px;
  object-fit: contain;
}

.about__us {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 32rem;
  gap: 3rem;
}
.about__us p {
  font-size: 4rem;
  line-height: 4.7rem;
  font-family: HelveticaNeueMedium;
  text-transform: inherit;
}
.about__us span {
  color: #a3a3a3;
  font-size: 1.6rem;
  display: block;
  width: 50%;
  margin-right: 0;
  margin-left: auto;
}
.about__us .video {
  display: block;
}
.about__us .video__item {
  height: 100%;
}

.about__team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1rem;
}
.about__team__item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 21rem auto auto;
  gap: 0.2rem;
  justify-self: center;
}
.about__team__item__img {
  width: 21rem;
  height: 21rem;
  border-radius: 100%;
  overflow: hidden;
}
.about__team__item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.about__team__item p {
  text-align: center;
  font-size: 2rem;
  font-family: HelveticaNeueMedium;
}
.about__team__item span {
  display: block;
  text-align: center;
  font-size: 1.3rem;
  color: #a3a3a3;
  margin-bottom: 1.6rem;
}

.banner {
  min-height: calc(90vh - 5.35rem);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 9rem;
}
.banner__text {
  font-size: 17.5rem;
  line-height: 25.45rem;
  text-align: center;
}
.banner__logo {
  opacity: 0.5;
  width: 5.35rem;
  margin: 0 auto;
}
.banner__logo--auto {
  margin: auto;
  width: 5.35rem;
}
.banner--medium {
  height: auto;
  min-height: auto;
  grid-auto-rows: auto;
  grid-template-rows: auto;
}
.banner--medium .banner__text {
  font-size: 10rem;
  line-height: 14.15rem;
  text-align: center;
  margin-top: 9rem;
}
.banner--medium p {
  font-size: 2.25rem;
  line-height: 3.15rem;
  display: block;
  text-align: center;
  border-radius: 1.2rem;
  padding: 3.6rem;
  background-color: #f1f1f1;
  margin-top: 9rem;
}

.product__banner {
  position: relative;
}
.product__banner img {
  display: block;
  position: relative;
  z-index: 2;
}
.product__banner__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 7.8rem;
  height: 100%;
}
.product__banner__text__top {
  position: absolute;
  top: 0.8rem;
  margin: 0 10.6rem;
  z-index: 1;
}
.product__banner__text__top p {
  font-size: 12.25rem;
  line-height: 10rem;
}
.product__banner__text__top p:last-child {
  margin-left: 30rem;
}
.product__banner__text__bottom {
  margin: 0 10.6rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 26%;
  z-index: 3;
}
.product__banner__text__bottom p {
  font-size: 12.25rem;
  line-height: 10rem;
  font-family: TimesNewRomanItalic;
}
.product__banner__text__bottom p.text__small {
  font-size: 5rem;
  line-height: 4rem;
  font-family: HelveticaNeueLight;
}
.product__banner__text .text__row {
  display: flex;
}
.product__banner__text .text__opacity {
  position: relative;
}
.product__banner__text .text__opacity p {
  left: 3rem;
  top: 0;
  position: absolute;
}
.product__banner__text .text__opacity p:first-child {
  opacity: 0.2;
}

.project__banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: auto;
  padding-top: 15rem;
  padding-bottom: 6rem;
  background-color: #f1f1f1;
  margin-top: -9rem;
  margin-bottom: 4rem;
}
.project__banner span {
  width: 50%;
}
.project__banner__text {
  font-size: 6.3rem;
  font-weight: 500;
}
.project__banner__small__text {
  font-size: 1.6rem;
  line-height: 2.24rem;
}

.characteristic {
  padding: 3.2rem;
  background-color: #f1f1f1;
  border-radius: 1.2rem;
  margin: 6rem 0;
  display: grid;
  grid-template-areas: "title image" "list image";
  justify-content: space-between;
}
.characteristic__title {
  grid-area: title;
  margin-bottom: 1.8rem;
}
.characteristic__title p {
  font-size: 2.25rem;
  line-height: 3rem;
  font-family: HelveticaNeueMedium;
}
.characteristic__title span {
  font-size: 1.6rem;
  color: #a3a3a3;
}
.characteristic__list {
  grid-area: list;
}
.characteristic__list__item {
  display: grid;
  grid-template-columns: 45% 55%;
  padding: 1.4rem 0;
  border-bottom: 1px solid rgba(163, 163, 163, 0.2);
}
.characteristic__list__item:last-child {
  border-bottom: 0;
}
.characteristic__list__item p,
.characteristic__list__item span {
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.characteristic__list__item p {
  color: #a3a3a3;
}
.characteristic__list__item__color {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(2.25rem, 1fr));
  gap: 0.8rem;
}
.characteristic__img {
  grid-area: image;
  margin-top: -3.2rem;
  margin-right: -3.2rem;
  max-width: 25rem;
}

.circle__color {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
}
.circle__color--white {
  background-color: #fff;
}
.circle__color--black {
  background-color: #131313;
}

.instruction {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 1rem;
}
.instruction__item {
  border-radius: 1.2rem;
  background-color: #131313;
  padding: 2rem;
}
.instruction__item__step {
  margin-bottom: 6.5rem;
  padding: 0.6rem 1.9rem;
  font-size: 1.2rem;
  background-color: #fff;
  text-align: center;
  border-radius: 3rem;
  text-transform: capitalize;
  display: inline-block;
}
.instruction__item__name {
  font-size: 2.25rem;
  margin-bottom: 2rem;
  font-family: HelveticaNeuBold;
  color: #fff;
}
.instruction__item__text {
  font-size: 1.6rem;
  color: #a3a3a3;
}
.instruction__img {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.instruction__img img {
  margin: auto;
}

.description {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 9rem;
}
.description__item {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 1rem;
}
.description__item__top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 4rem;
  align-items: center;
}
.description__item__top p {
  font-size: 1.6rem;
  line-height: 1.2;
}
.description__item__top__name {
  display: flex;
  align-items: center;
}
.description__item__top__name span {
  display: block;
  margin-right: 2.25rem;
  font-size: 1.6rem;
  color: #a3a3a3;
}
.description__item__top__name p {
  font-size: 4rem;
  font-family: HelveticaNeueMedium;
}
.description__item__bottom {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  gap: 1rem;
}
.description__item__bottom--gallery {
  grid-template-areas: "first second" "third third";
}
.description__item__bottom--gallery .description__item__bottom__img:nth-child(1) {
  grid-area: first;
}
.description__item__bottom--gallery .description__item__bottom__img:nth-child(2) {
  grid-area: second;
}
.description__item__bottom--gallery .description__item__bottom__img:nth-child(3) {
  grid-area: third;
}
.description__item__bottom--row {
  grid-template-columns: 1fr 1fr;
}
.description__item__bottom--video .video__item {
  height: 29rem;
}
.description__item__bottom__img {
  border-radius: 1.2rem;
  height: 29rem;
}
.description__item__bottom__img img {
  border-radius: 1.2rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.download {
  border-radius: 1.2rem;
  background-color: #f1f1f1;
  padding: 3rem 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.download__title {
  font-size: 2.25rem;
  margin-bottom: 3rem;
  font-family: HelveticaNeueMedium;
  text-align: center;
}
.download__list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}
.download__list__item {
  display: flex;
  align-items: center;
}
.download__list__item .icon {
  width: 1.6rem;
}
.download__list__item span {
  margin-left: 0.7rem;
  font-size: 1.6rem;
}

.guide {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, min(33.3333333333%, max(16.5rem, 33.3333333333%))));
}
.guide__item {
  display: flex;
  flex-direction: column;
  max-width: 16.5rem;
  margin: 0 auto;
}
.guide__item__img {
  border-radius: 1.2rem;
  width: 16.5rem;
  height: 16.5rem;
  margin-bottom: 1.6rem;
}
.guide__item__img .image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
}
.guide__item__text p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.guide__item__text p span {
  color: #a3a3a3;
}
.guide__item__text__name {
  margin-top: 1.6rem;
  font-size: 2.25rem;
  margin-bottom: 3rem;
  font-family: HelveticaNeueMedium;
}

.guide__info {
  width: 60rem;
  margin: 3rem auto;
}
.guide__info__title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
}
.guide__info__title span {
  text-align: center;
  font-size: 1.6rem;
  color: #a3a3a3;
}
.guide__info__title h2 {
  font-size: 4rem;
  line-height: 4.8rem;
  text-align: center;
  margin: 0;
  text-transform: inherit;
}
.guide__info__text p {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
.guide__info__text ul {
  border-radius: 1.2rem;
  padding: 3rem;
  background-color: #f1f1f1;
  list-style-type: disc;
  margin-bottom: 3rem;
}
.guide__info__text ul li {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
}
.guide__info__text .image {
  border-radius: 1.2rem;
  margin-bottom: 3rem;
  width: 100%;
  object-fit: cover;
}
.guide__info__text h3,
.guide__info__text h4 {
  margin-bottom: 3rem;
}
.guide__info--row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto;
  gap: 1rem;
  margin-bottom: 3rem;
}
.guide__info--row .image {
  margin-bottom: 0;
}
.guide__info span {
  font-size: 1.6rem;
  color: #a3a3a3;
  margin-bottom: 3rem;
  display: block;
}
.guide__info .video {
  margin-bottom: 2rem;
}
.guide__info .products--fill {
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
}

.effects__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, min(50%, max(16.5rem, 33.3333333333%))));
}
.effects__list .item {
  padding: 0.5rem;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
}
.effects__list .item__img img {
  border-radius: 1.2rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 32rem;
  margin-bottom: 1.6rem;
}
.effects__list .item__text span {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #a3a3a3;
}
.effects__list .item__text p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  font-family: HelveticaNeueMedium;
}

.booking {
  background-color: #131313;
  padding: 6rem 3rem 0 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 4rem;
}
.booking__information p {
  margin-bottom: 5rem;
  font-size: 3.7rem;
  line-height: 4.5rem;
  color: #fff;
}
.booking__information__team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  gap: 2.5rem;
}
.booking__information__team img {
  width: 12.5rem;
  max-width: 12.5rem;
  height: 12.5rem;
  border-radius: 100%;
  object-fit: cover;
  margin: 0 auto;
}

.form {
  border-radius: 1.2rem;
  padding: 3rem;
  background-color: #f1f1f1;
}
.form__group {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}
.form__group__input {
  border: 0;
  border-bottom: 1px solid #131313;
  font-size: 1.6rem;
  height: 2.8rem;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  font-weight: 400;
}
.form__group__input.wpcf7-not-valid {
  border-bottom-color: red;
}
.form__group__input:focus + label, .form__group__input:valid + label {
  transform: scale(0.85) translateY(-1.8rem);
}
.form__group .wpcf7-form-control-wrap {
  display: inline-block;
  width: 100%;
}
.form__group .wpcf7-form-control-wrap.focus ~ label {
  transform: scale(0.85) translateY(-1.8rem);
}
.form__group__label {
  font-size: 1.6rem;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  pointer-events: none;
  margin: 0.4rem 0;
  transform-origin: left top;
  transition-property: transform;
  transition-duration: 200ms;
  color: #a3a3a3;
}
.form__group__label--top {
  top: 0;
}
.form__group textarea {
  height: 13rem;
  border: 1px solid #131313;
  font-size: 1rem;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  font-weight: 400;
  margin-top: 1.6rem;
  position: relative;
}
.form__group__title {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 0.2rem;
  color: #a3a3a3;
}
.form__group__title--bold {
  color: #131313;
  font-family: HelveticaNeueMedium;
}
.form__group__btn button {
  width: 100%;
}
.form__group__btn .main__small_btn--arrow {
  padding-right: 1rem;
  margin-bottom: 0;
}
.form .radio {
  grid-template-columns: repeat(2, minmax(0, 12rem));
}

.booking__form input[type=submit] {
  width: 100%;
}

.footer {
  background-color: #131313;
  padding: 6rem 3rem 4rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.footer__information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__information p {
  margin-bottom: 2.6rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #a3a3a3;
  width: 70%;
}
.footer__information .main__small_btn {
  margin-left: 0;
}
.footer__nav {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3rem;
}
.footer__nav a {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #fff;
  font-family: HelveticaNeueMedium;
  margin-bottom: 0.4rem;
  display: block;
  white-space: nowrap;
}
.footer__nav a:hover {
  text-decoration: underline;
}
.footer__nav p {
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #a3a3a3;
  opacity: 0.6;
  margin-top: 1rem;
}

/* свойства модального окна по умолчанию */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  -webkit-transition: opacity 0 ease-in;
  -moz-transition: opacity 0 ease-in;
  transition: opacity 0 ease-in;
  pointer-events: none;
  margin: 0;
  padding: 0;
  display: none;
  pointer-events: auto;
  overflow-y: auto;
}
.modal__dialog {
  position: relative;
  width: 53.5rem;
  margin: 2rem auto;
}
.modal__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 3rem;
  outline: 0;
  padding: 2.5rem;
}
.modal__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.modal .close {
  float: right;
  width: 1.6rem;
  height: 1.6rem;
  opacity: 0.5;
  text-decoration: none;
  position: absolute;
  right: 0;
  z-index: 10;
}
.modal .close:focus, .modal .close:hover {
  opacity: 0.75;
}
.modal__logo {
  width: 5.35rem;
  margin: 3rem auto 2rem;
}
.modal__body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: auto;
}
.modal__body > p {
  margin-bottom: 2rem;
  line-height: 3.5rem;
  font-size: 2.5rem;
  font-family: HelveticaNeuBold;
  text-align: center;
}
.modal__body .form__group {
  width: 30rem;
  margin: 3rem auto 5.5rem;
}
.modal__body .form__group--100 {
  width: 100%;
  margin: 0 0 2rem;
}
.modal__body .form__group__title {
  font-family: HelveticaNeuBold;
  font-size: 1.2rem;
  color: #131313;
  text-align: left;
  margin-bottom: 0.5rem;
}
.modal__body .form__group--100.form__group__btn {
  width: 100%;
  margin: 0 auto;
}
.modal__body .form__group--100.form__group__btn .main__btn {
  margin: 0;
}
.modal__body .form__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
.modal__body .form__row__col .radio label {
  width: 45%;
}
.modal__body .radio label {
  width: auto;
  min-width: 11rem;
}

.modal-overlay {
  filter: alpha(opacity=0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
}

@media (max-width: 1800px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  html {
    font-size: 11px;
  }
  .calculator {
    grid-template-columns: 1fr 1fr;
  }
  .calculator__form {
    padding: 1.6rem 1.5rem;
  }
}
@media (max-width: 1040px) {
  html {
    font-size: 10px;
  }
  .booking {
    grid-template-columns: 0.7fr 1.3fr;
  }
}
@media (max-width: 992px) {
  h1 {
    font-size: 7rem;
    line-height: 7.6rem;
  }
  h2 {
    margin-top: 7.6rem;
    padding: 0 1.5rem;
  }
  h2.h2__big {
    font-size: 6rem;
    line-height: 7rem;
  }
  .hamburger {
    display: block;
  }
  .booking {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
  header {
    padding: 0;
  }
  header nav {
    display: grid;
    grid-template-areas: "logo hamburger" "mainMenu mainMenu" "secondMenu secondMenu";
    grid-template-columns: 1fr;
    grid-template-rows: 7rem auto auto auto;
  }
  header.active nav {
    min-height: 100vh;
  }
  header .nav {
    display: none;
  }
  header .nav__item:last-child {
    padding-right: 1.4rem;
  }
  header .nav.nav--main {
    padding-bottom: 2rem;
    grid-area: mainMenu;
  }
  header .nav.nav--second {
    padding-bottom: 2rem;
    grid-area: secondMenu;
  }
  header .hamburger {
    display: block;
    grid-area: hamburger;
  }
  header .logo {
    padding-left: 1.5rem;
    grid-area: logo;
  }
  header .nav__dropdown__list {
    width: 100%;
    left: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: #fff;
  }
  header .nav__dropdown__item {
    margin: 0 auto;
  }
  .about__team {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .about__team__item {
    grid-template-rows: auto auto auto;
  }
  .about__team__item__img {
    width: 100%;
    height: 100%;
  }
  .product__banner__text__top p {
    font-size: 10rem;
  }
  .product__banner__text__top p:last-child {
    margin-left: 20rem;
  }
  .product__banner__text__bottom p {
    font-size: 10rem;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 8px;
  }
  h1 {
    font-size: 5rem;
    line-height: 5.6rem;
  }
  h2 {
    margin-top: 5.6rem;
    padding: 0 1.5rem;
  }
  h2.h2__big {
    font-size: 4rem;
    line-height: 5rem;
  }
  .main__padding {
    padding: 0 1.5rem;
  }
  .main__padding.project__banner {
    padding-top: 15rem;
    padding-bottom: 6rem;
  }
  .main__btn {
    margin: 4rem 1.5rem 3rem;
    height: 8rem;
  }
  .xs_padding_0 {
    padding: 0;
  }
  .radio [type=radio]:checked + span span:after,
  .radio [type=radio]:not(:checked) + span span:after {
    top: 0.5rem;
    left: 0.4rem;
  }
  .slider {
    min-height: 73vh;
  }
  .slider__text {
    justify-content: center;
    height: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 5rem;
  }
  .slider__text__top {
    margin-left: 0;
    margin-right: 0;
    margin-top: 15rem;
  }
  .slider__text__top h1 {
    align-items: center;
    text-align: center;
  }
  .slider__text__top h1 > span {
    margin-left: 0;
  }
  .gallery {
    grid-template: "big" 1fr "small_one" 1fr "small_two" 1fr;
  }
  .products {
    grid-template-columns: repeat(1, minmax(16rem, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }
  .calculator {
    grid-template-columns: 1fr;
  }
  .calculator__form {
    padding: 1.6rem 5rem;
  }
  .about__founder {
    grid-template-columns: 1fr;
    grid-template-rows: 40rem auto;
    gap: 1rem;
    margin-bottom: 3rem;
  }
  .about__founder__text {
    padding-left: 0;
  }
  .about__founder__text p {
    font-size: 8rem;
    line-height: 9.5rem;
  }
  .booking {
    grid-template-columns: 1fr;
  }
  .footer {
    grid-template-columns: 0.6fr 1.4fr;
  }
  .footer__information p {
    width: 100%;
  }
  .banner__text {
    font-size: 9rem;
    line-height: 11rem;
  }
  .banner--medium .banner__text {
    font-size: 7rem;
  }
  .about__us p {
    line-height: inherit;
  }
  .about__us span {
    width: 100%;
    margin-left: 0;
  }
  .projects__item {
    flex-direction: column;
  }
  .projects__item > div:first-child {
    margin-right: 0;
    margin-bottom: 3rem;
  }
  .projects__item__img {
    width: 100%;
  }
  .projects__item__text {
    width: 100%;
    padding-left: 0;
    padding-top: 2rem;
    padding-top: 0;
  }
  .projects--about .projects__item__img {
    width: 100%;
  }
  .projects--about .projects__item__text {
    width: 100%;
  }
  .characteristic {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .characteristic__title {
    order: 1;
  }
  .characteristic__list {
    order: 3;
  }
  .characteristic__img {
    order: 2;
    margin: 0 auto;
  }
  .project__banner {
    flex-direction: column;
    min-height: auto;
    align-items: flex-start;
  }
  .project__banner span {
    width: 100%;
  }
  .project__banner__text {
    margin-bottom: 3.5rem;
  }
  .project .img__hover__transform {
    height: 28rem;
  }
  .project__photos {
    flex-direction: column;
  }
  .project__photos__img {
    flex-direction: column;
    display: flex;
    height: auto;
  }
  .project__photos .img__hover__transform {
    height: 20rem;
  }
}
@media (max-width: 567px) {
  .slider__text__bottom {
    width: 100%;
  }
  .calculator__form {
    padding: 1.6rem 1.2rem;
  }
  .video {
    grid-template-columns: 1fr;
  }
  .carousel .owl-stage {
    padding: 0 !important;
  }
  .booking__information__team img {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .footer {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
  .footer__information {
    grid-row-start: 2;
  }
  .footer__information p {
    width: 100%;
  }
  .footer__nav {
    margin-top: 0;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    justify-items: stretch;
    align-items: stretch;
    gap: 0;
    grid-row-start: 1;
  }
  .about__team {
    grid-template-columns: 1fr 1fr;
  }
  .modal__dialog {
    max-width: 85%;
    margin: 1rem auto;
  }
  .modal__body .form__group {
    width: 100%;
  }
  .modal__body .form__group__btn {
    max-width: 100%;
  }
  .modal__body .form__group__btn .main__btn {
    margin: 0 auto;
  }
  .instruction {
    grid-template-columns: 1fr;
  }
  .product__banner__text__top {
    margin: 0 5rem;
  }
  .product__banner__text__top p {
    font-size: 6rem;
  }
  .product__banner__text__top p:last-child {
    margin-left: 8rem;
  }
  .product__banner__text__bottom {
    margin: 0 5rem;
  }
  .product__banner__text__bottom p {
    font-size: 6rem;
  }
  .description {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 9rem;
  }
  .description__item__top {
    grid-template-columns: 1fr;
    grid-row: auto;
    margin-bottom: 2rem;
  }
  .description__item__top__name p {
    font-size: 2rem;
  }
  .description__item__bottom--gallery {
    grid-template-areas: "first" "second" "third";
  }
  .guide {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, min(100%, max(16.5rem, 100%))));
  }
  .guide__item {
    max-width: 100%;
    margin-bottom: 2rem;
  }
  .guide__item__img {
    width: 100%;
  }
  .guide__info {
    width: 100%;
  }
  .guide__info--row {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }
  .guide__info--row .image {
    margin-bottom: 1rem;
  }
  .effects__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, min(100%, max(16.5rem, 100%))));
  }
  .effects__list .item {
    margin-bottom: 2.5rem;
  }
}