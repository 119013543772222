@media (max-width: 1800px) {
  html {
    font-size: 17px;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1280px) {
  html {
    font-size: 11px;
  }
  .calculator {
    grid-template-columns: 1fr 1fr;

    &__form {
      padding: 1.6rem 1.5rem;
    }
  }
}

@media (max-width: 1040px) {
  html {
    font-size: 10px;
  }
  .booking {
    grid-template-columns: 0.7fr 1.3fr;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 7rem;
    line-height: 7.6rem;
  }

  h2 {
    margin-top: 7.6rem;
    padding: 0 1.5rem;
  }

  h2.h2__big {
    font-size: 6rem;
    line-height: 7rem;
  }
  .hamburger {
    display: block;
  }
  .booking {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
  header {
    padding: 0;
    nav {
      display: grid;
      grid-template-areas:
        'logo hamburger'
        'mainMenu mainMenu'
        'secondMenu secondMenu';
      grid-template-columns: 1fr;
      grid-template-rows: 7rem auto auto auto;
    }

    &.active {
      nav {
        min-height: 100vh;
      }
    }
    .nav {
      display: none;
      &__item:last-child {
        padding-right: 1.4rem;
      }
      &.nav--main {
        padding-bottom: 2rem;
        grid-area: mainMenu;
      }
      &.nav--second {
        padding-bottom: 2rem;
        grid-area: secondMenu;
      }
    }
    .hamburger {
      display: block;
      grid-area: hamburger;
    }
    .logo {
      padding-left: 1.5rem;
      grid-area: logo;
    }

    .nav__dropdown {
      &__list {
        width: 100%;
        left: 0;
        box-shadow: none;
        border-radius: 0;
        background-color: $white_color;
      }
      &__item {
        margin: 0 auto;
      }
    }
  }

  .about__team {
    grid-template-columns: 1fr 1fr 1fr;
    &__item {
      grid-template-rows: auto auto auto;

      &__img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .product__banner {
    &__text {
      &__top {
        p {
          font-size: 10rem;
          &:last-child {
            margin-left: 20rem;
          }
        }
      }

      &__bottom {
        p {
          font-size: 10rem;
          &.text__small {
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  html {
    font-size: 8px;
  }
  h1 {
    font-size: 5rem;
    line-height: 5.6rem;
  }

  h2 {
    margin-top: 5.6rem;
    padding: 0 1.5rem;
  }

  h2.h2__big {
    font-size: 4rem;
    line-height: 5rem;
  }

  .main__padding {
    padding: 0 1.5rem;
    &.project__banner {
      padding-top: 15rem;
      padding-bottom: 6rem;
    }
  }

  .main__btn {
    margin: 4rem 1.5rem 3rem;
    height: 8rem;
  }

  .xs_padding_0 {
    padding: 0;
  }

  .radio [type='radio']:checked + span span:after,
  .radio [type='radio']:not(:checked) + span span:after {
    top: 0.5rem;
    left: 0.4rem;
  }

  .slider {
    min-height: 73vh;

    &__text {
      justify-content: center;
      height: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 5rem;

      &__top {
        margin-left: 0;
        margin-right: 0;
        margin-top: 15rem;
        h1 {
          align-items: center;
          text-align: center;

          & > span {
            margin-left: 0;
          }
        }
      }
    }
  }

  .gallery {
    grid-template:
      'big' 1fr
      'small_one' 1fr
      'small_two' 1fr;
  }

  .products {
    grid-template-columns: repeat(1, minmax(16rem, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .calculator {
    grid-template-columns: 1fr;

    &__form {
      padding: 1.6rem 5rem;
    }
  }

  .about__founder {
    grid-template-columns: 1fr;
    grid-template-rows: 40rem auto;
    gap: 1rem;
    margin-bottom: 3rem;

    &__text {
      padding-left: 0;

      p {
        font-size: 8rem;
        line-height: 9.5rem;
      }
    }
  }

  .booking {
    grid-template-columns: 1fr;
  }

  .footer {
    grid-template-columns: 0.6fr 1.4fr;

    &__information {
      p {
        width: 100%;
      }
    }
  }
  .banner {
    &__text {
      font-size: 9rem;
      line-height: 11rem;
    }

    &--medium {
      .banner__text {
        font-size: 7rem;
      }
    }
  }

  .about__us {
    p {
      line-height: inherit;
    }

    span {
      width: 100%;
      margin-left: 0;
    }
  }

  .projects {
    &__item {
      flex-direction: column;

      & > div:first-child {
        margin-right: 0;
        margin-bottom: 3rem;
      }

      &__img {
        width: 100%;
      }

      &__text {
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
        padding-top: 0;
      }
    }

    &--about {
      .projects__item {
        &__img {
          width: 100%;
        }

        &__text {
          width: 100%;
        }
      }
    }
  }

  .characteristic {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    &__title {
      order: 1;
    }
    &__list {
      order: 3;
    }
    &__img {
      order: 2;
      margin: 0 auto;
    }
  }
  .project__banner {
    flex-direction: column;
    min-height: auto;
    align-items: flex-start;

    span {
      width: 100%;
    }

    &__text {
      margin-bottom: 3.5rem;
    }
  }
  .project {
    .img__hover__transform {
      height: 28rem;
    }

    &__photos {
      flex-direction: column;

      &__img {
        flex-direction: column;
        display: flex;
        height: auto;
      }

      .img__hover__transform {
        height: 20rem;
      }
    }
  }
}

@media (max-width: 567px) {
  .slider__text__bottom {
    width: 100%;
  }

  .calculator {
    &__form {
      padding: 1.6rem 1.2rem;
    }
  }

  .video {
    grid-template-columns: 1fr;
  }

  .carousel {
    .owl-stage {
      padding: 0 !important;
    }
  }

  .booking {
    &__information {
      &__team {
        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }
  .footer {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;

    &__information {
      grid-row-start: 2;
      p {
        width: 100%;
      }
    }
    &__nav {
      margin-top: 0;
      grid-template-columns: repeat(auto-fill, 100%);
      grid-auto-columns: auto;
      grid-auto-rows: auto;
      justify-items: stretch;
      align-items: stretch;
      gap: 0;
      grid-row-start: 1;
    }
  }
  .about__team {
    grid-template-columns: 1fr 1fr;
  }
  .modal {
    &__dialog {
      max-width: 85%;
      margin: 1rem auto;
    }

    &__body {
      .form__group {
        width: 100%;
      }
      .form__group__btn {
        max-width: 100%;
        .main__btn {
          margin: 0 auto;
        }
      }
    }
  }
  .instruction {
    grid-template-columns: 1fr;
  }
  .product__banner {
    &__text {
      &__top {
        margin: 0 5rem;
        p {
          font-size: 6rem;
          &:last-child {
            margin-left: 8rem;
          }
        }
      }

      &__bottom {
        margin: 0 5rem;
        p {
          font-size: 6rem;
          &.text__small {
          }
        }
      }
    }
  }
  .description {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 9rem;

    &__item {
      &__top {
        grid-template-columns: 1fr;
        grid-row: auto;
        margin-bottom: 2rem;
        &__name {
          p {
            font-size: 2rem;
          }
        }
      }
      &__bottom {
        &--gallery {
          grid-template-areas:
            'first'
            'second'
            'third';
        }
      }
    }
  }
  .guide {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(0, min(100%, max(16.5rem, 100%)))
    );

    &__item {
      max-width: 100%;
      margin-bottom: 2rem;

      &__img {
        width: 100%;
      }
    }
  }
  .guide__info {
    width: 100%;
    &--row {
      grid-template-columns: 1fr;
      margin-bottom: 2rem;
      .image {
        margin-bottom: 1rem;
      }
    }
  }
  .effects__list {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(0, min(100%, max(16.5rem, 100%)))
    );

    .item {
      margin-bottom: 2.5rem;
    }
  }
}
