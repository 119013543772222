.video {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 1rem;

  &--1fr {
    grid-template-columns: 1fr;
  }

  &__item {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &__img {
      position: relative;
    }

    &__text {
      margin-top: 1rem;
      font-size: 2.25rem;
      font-family: $font_medium;
    }

    &__shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #131313;
      opacity: 0.5;
      top: 0;
      left: 0;
      border-radius: 1rem;
    }
  }
}

.play {
  width: 6rem;
  height: 6rem;
  background-color: $white_color;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 3rem);
  top: calc(50% - 3rem);
  z-index: 10;

  img {
    width: 30px;
    object-fit: contain;
  }
}
