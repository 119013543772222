.booking {
  background-color: $brand_color;
  padding: 6rem 3rem 0 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 4rem;

  &__information {
    p {
      margin-bottom: 5rem;
      font-size: 3.7rem;
      line-height: 4.5rem;
      color: $white_color;
    }

    &__team {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: auto;
      gap: 2.5rem;

      img {
        width: 12.5rem;
        max-width: 12.5rem;
        height: 12.5rem;
        border-radius: 100%;
        object-fit: cover;
        margin: 0 auto;
      }
    }
  }
}

.form {
  border-radius: 1.2rem;
  padding: 3rem;
  background-color: $background_white_color--third;

  &__group {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    &__input {
      border: 0;
      border-bottom: 1px solid $brand_color;
      font-size: 1.6rem;
      height: 2.8rem;
      border-radius: 0;
      background-color: transparent;
      width: 100%;
      font-weight: 400;

      &.wpcf7-not-valid {
        border-bottom-color: red;
      }

      &:focus + label,
      &:valid + label {
        transform: scale(0.85) translateY(-1.8rem);
      }
       
    }

    .wpcf7-form-control-wrap {
      display: inline-block;
      width: 100%;
    }

    .wpcf7-form-control-wrap.focus ~ label {
      transform: scale(0.85) translateY(-1.8rem);
    }
   

    &__label {
      font-size: 1.6rem;
      bottom: 0px;
      left: 0px;
      z-index: 2;
      position: absolute;
      pointer-events: none;
      margin: 0.4rem 0;
      transform-origin: left top;
      transition-property: transform;
      transition-duration: 200ms;
      color: $brand_darker_color;

      &--top {
        top: 0;
      }
    }

    textarea {
      height: 13rem;
      border: 1px solid $brand_color;
      font-size: 1rem;
      border-radius: 0;
      background-color: transparent;
      width: 100%;
      font-weight: 400;
      margin-top: 1.6rem;
      position: relative;
    }

    &__title {
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-bottom: 0.2rem;
      color: $brand_darker_color;

      &--bold {
        color: $brand_color;
        font-family: $font_medium;
      }
    }

    &__btn {
      button {
        width: 100%;
      }
      .main__small_btn--arrow {
        padding-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .radio {
    grid-template-columns: repeat(2, minmax(0, 12rem));
  }
}
