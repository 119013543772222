.gallery {
  display: grid;
  grid-template:
    'big small_one' 1fr
    'big small_two' 1fr / 6.7fr 5fr;
  gap: 1px;

  &__item {
    position: relative;
    &__img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      font-size: 2.25rem;
      color: $white_color;
      font-family: $font_medium;
      transition: transform 0.4s ease-in-out;
    }
    &__shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      background: $brand_color;
      opacity: 0.35;
      top: 0;
      left: 0;
      transition: opacity 0.4s ease-in-out 0s;
    }

    &:hover {
      .gallery__item__shadow {
        opacity: 0.75;
      }
      .gallery__item__text {
        transform: scale(1.3);
      }
    }
    &--big {
      background-color: lime;
      grid-area: big;
    }
    &--one {
      background-color: red;
      grid-area: small_one;
    }
    &--two {
      background-color: red;
      grid-area: small_two;
    }
  }
}
