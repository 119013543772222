.carousel {
  overflow: hidden;
  margin-bottom: 8rem;

  .owl-stage {
    padding: 0 0 0 3rem !important;
  }

  .item {
    padding: 3rem 2rem 3rem 3rem;
    border-radius: 1.2rem;
    background-color: $background_white_color--third;
    display: flex;
    flex-direction: column;

    &__img {
      img {
        border-radius: 1.2rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        height: 32rem;
      }
    }

    &__top {
      display: grid;
      grid-template-columns: 5rem 1fr;
      gap: 2rem;
      margin-bottom: 2rem;
      &__image {
        width: 5rem;
        height: 5rem;
        border-radius: 100%;
        background-color: $background_white_color--third;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      &__text {
        &__name {
          margin-bottom: 0.7rem;
          font-size: 2.2rem;
          line-height: 2.7rem;
          font-family: $font_medium;
        }
        &__position {
          margin-bottom: 0.4rem;
          font-size: 1.2rem;
          line-height: 1.4rem;
        }

        &__data {
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: $brand_darker_color;
        }
      }
    }

    &__bottom {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}

.effects--text {
  .item {
    background-color: $white-color;
    &__img {
      img {
        margin-bottom: 1.6rem;
      }
    }
    &__text {
      span {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: $brand_darker_color;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-transform: uppercase;
        font-family: $font_medium;
      }
    }
  }
}
