//fonts
$font_light: HelveticaNeueLight;
$font_medium: HelveticaNeueMedium;
$font_bold: HelveticaNeuBold;
$font_italic: TimesNewRomanItalic;

//colors
$brand_color: #131313;
$brand_darker_color: #a3a3a3;
$brand_darker_color_opacity: rgba(163, 163, 163, 0.6);
$brand_darker_border_color_opacity: rgba(163, 163, 163, 0.2);
$background_white_color: #e2e2e2;
$background_white_color--second: #ededf3;
$background_white_color--third: #f1f1f1;

$light_color: #e2e8f0;
$light_color--second: #9e9e9e;
$white_color: #fff;

$main_padding: 0 3rem;

//body
$body_background_color: #fff;

//nav
$nav_font_size: 1.6rem;

//dropdown
$dropdown_background_color: #f4f4f4;
$dropdown_shadow: 0px 15px 50px rgba(0, 0, 0, 0.2);
