.booking__form {
  input[type="submit"] {
    width: 100%;
  }
}

.footer {
  background-color: $brand_color;
  padding: 6rem 3rem 4rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  &__information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-bottom: 2.6rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $brand_darker_color;
      width: 70%;
    }
    .main__small_btn {
      margin-left: 0;
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 3rem;

    a {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $white_color;
      font-family: $font_medium;
      margin-bottom: 0.4rem;
      display: block;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $brand_darker_color;
      opacity: 0.6;
      margin-top: 1rem;
    }
  }
}
