@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('../fonts/HelveticaNeueLight.ttf') format('ttf'),
    url('../fonts/HelveticaNeueLight.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('../fonts/HelveticaNeueMedium.ttf') format('ttf'),
    url('../fonts/HelveticaNeueMedium.woff') format('woff');
}

@font-face {
  font-family: 'HelveticaNeuBold';
  src: url('../fonts/HelveticaNeuBold.ttf') format('ttf'),
    url('../fonts/HelveticaNeueBold.woff') format('woff');
}

@font-face {
  font-family: 'TimesNewRomanItalic';
  src: url('../fonts/TimesNewRomanItalic.ttf') format('ttf'),
    url('../fonts/TimesNewRomanItalic.woff') format('woff');
}
