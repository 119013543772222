.products {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-bottom: 5rem;

  &__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: $background_white_color;
    border-radius: 1.2rem;
    overflow: hidden;
    position: relative;
    -webkit-transition: background-color 0.4s ease-in-out;
    transition: background-color 0.4s ease-in-out;

    &__img {
      position: relative;
      transition: transform 0.4s ease-in-out 0s;
      margin-left: auto;
      width: 68%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:hover {
      .products__item__img {
        transform: scale(1.1);
      }
    }

    &__text {
      background-color: $brand_color;
      color: $white_color;
      font-size: 2.25rem;
      display: flex;
      align-items: center;
      width: 100%;
      height: 6.35rem;
      padding: 0 2rem;
      font-family: $font_medium;
    }
  }
}
