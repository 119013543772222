.download {
  border-radius: 1.2rem;
  background-color: $background_white_color--third;
  padding: 3rem 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 2.25rem;
    margin-bottom: 3rem;
    font-family: $font_medium;
    text-align: center;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    &__item {
      display: flex;
      align-items: center;

      .icon {
        width: 1.6rem;
      }
      span {
        margin-left: 0.7rem;
        font-size: 1.6rem;
      }
    }
  }
}
