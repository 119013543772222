.instruction {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 1rem;

  &__item {
    border-radius: 1.2rem;
    background-color: $brand_color;
    padding: 2rem;

    &__step {
      margin-bottom: 6.5rem;
      padding: 0.6rem 1.9rem;
      font-size: 1.2rem;
      background-color: $white_color;
      text-align: center;
      border-radius: 3rem;
      text-transform: capitalize;
      display: inline-block;
    }
    &__name {
      font-size: 2.25rem;
      margin-bottom: 2rem;
      font-family: $font_bold;
      color: $white_color;
    }
    &__text {
      font-size: 1.6rem;
      color: $brand_darker_color;
    }
  }

  &__img {
    margin: 2rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: auto;
    }
  }
}
