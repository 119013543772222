.about {
  &__founder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5rem;
    &__img {
      max-height: 37rem;
    }

    &__text {
      display: flex;
      flex-direction: column;
      padding-left: 4rem;
      padding-top: 0.2rem;

      span {
        font-size: 1.6rem;
        color: $brand_darker_color;
        margin-bottom: 1.2rem;
        display: block;
      }

      p {
        color: $brand_color;
        font-size: 10rem;
        line-height: 12.5rem;
        margin-top: 2rem;
      }
    }
  }

  &__quote {
    border-radius: 1.2rem;
    background-color: $background_white_color--third;
    padding: 1rem;
    span {
      line-height: 5.5rem;
      font-size: 4rem;
      font-family: $font_italic;
    }
  }
}
