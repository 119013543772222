.slider {
  height: 50.5rem;
  width: 100%;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  &__img {
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    background: $brand_color;
    opacity: 0.65;
    top: 0;
    left: 0;
    border-radius: 1rem;
  }
  &__text {
    display: grid;
    height: 50.5rem;
    width: 100%;
    position: absolute;
    grid-template-rows: 1fr auto;
    top: 0;
    z-index: 1;
    padding-bottom: 4rem;

    &--left {
      text-align: right;
    }

    &__italic {
      font-family: $font_italic;
      color: $white_color;
    }

    &__top {
      margin: 5.7rem 1.5rem 0 8.5rem;
      h1 {
        display: flex;
        flex-direction: column;
        & > span {
          margin-left: 7.6rem;
          color: $white_color;          
        }
      }
      &--gallery {
        h1 > span {
          margin-left: 35rem;
        }
        
      }
    }

    &__bottom {
      font-size: $nav_font_size;
      line-height: 2.2rem;
      color: $white_color;
      padding: 1.5rem 1.7rem;
      border: 1px solid $white_color;
      width: 35.95rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
        color: $white_color;
      }
    }
  }
}
