.description {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 9rem;

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 1rem;
    &__top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-bottom: 4rem;
      align-items: center;
      p {
        font-size: 1.6rem;
        line-height: 1.2;
      }
      &__name {
        display: flex;
        align-items: center;

        span {
          display: block;
          margin-right: 2.25rem;
          font-size: 1.6rem;
          color: $brand_darker_color;
        }

        p {
          font-size: 4rem;
          font-family: $font_medium;
        }
      }
    }
    &__bottom {
      display: grid;
      grid-template-rows: 1fr;
      grid-auto-rows: auto;
      gap: 1rem;
      &--gallery {
        grid-template-areas:
          'first second'
          'third third';

        .description__item__bottom__img {
          &:nth-child(1) {
            grid-area: first;
          }
          &:nth-child(2) {
            grid-area: second;
          }
          &:nth-child(3) {
            grid-area: third;
          }
        }
      }
      &--row {
        grid-template-columns: 1fr 1fr;
      }
      &--video {
        .video__item {
          height: 29rem;
        }
      }
      &__img {
        border-radius: 1.2rem;
        height: 29rem;

        img {
          border-radius: 1.2rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
