.characteristic {
  padding: 3.2rem;
  background-color: #f1f1f1;
  border-radius: 1.2rem;
  margin: 6rem 0;
  display: grid;
  grid-template-areas:
    'title image'
    'list image';
  justify-content: space-between;

  &__title {
    grid-area: title;
    margin-bottom: 1.8rem;

    p {
      font-size: 2.25rem;
      line-height: 3rem;
      font-family: $font_medium;
    }
    span {
      font-size: 1.6rem;
      color: $brand_darker_color;
    }
  }

  &__list {
    grid-area: list;
    &__item {
      display: grid;
      grid-template-columns: 45% 55%;
      padding: 1.4rem 0;
      border-bottom: 1px solid $brand_darker_border_color_opacity;

      &:last-child {
        border-bottom: 0;
      }
      p,
      span {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
      p {
        color: $brand_darker_color;
      }

      &__color {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(2.25rem, 1fr));
        gap: 0.8rem;
      }
    }
  }

  &__img {
    grid-area: image;
    margin-top: -3.2rem;
    margin-right: -3.2rem;
    max-width: 25rem;
  }
}

.circle__color {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;

  &--white {
    background-color: $white_color;
  }

  &--black {
    background-color: $brand_color;
  }
}
