header {
  display: grid;
  align-items: center;
  height: 7.35rem;
  width: 100%;
  padding: $main_padding;
  position: fixed;
  top: 0;
  z-index: 100;

  &.header__fixed,
  &.active {
    background-color: $white_color;

    nav {
      background-color: $white_color;
    }
  }

  .hamburger {
    display: none;
    width: 4rem;
    padding-right: 1.5rem;
    margin-top: 0.3rem;
  }

  &.active {
    background: $white_color;
    width: 100%;

    .nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      background: #fff;
      top: 3rem;
      padding: 0;
      margin: 0;
      width: 100%;
      left: 0;
      right: 0;
    }
  }
  .logo {
    width: 5.35rem;
    display: block;
    margin-top: 0.2rem;
    &__picture {
      width: 5.35rem;
      display: block;

      img {
        width: 5.35rem;
      }
    }
  }
}
