* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $brand_color;
}

html {
  font-size: 20px;
  line-height: 1.5;
}

body {
  font-family: $font_light;
  background-color: $body_background_color;
  color: $brand_color;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea {
  outline: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

.font-medium {
  font-family: $font_medium !important;
}

.font-light {
  font-family: $font_light !important;
}

h1 {
  font-size: 10rem;
  line-height: 9.6rem;
  color: $white_color;
  font-weight: 400;
}

h2 {
  font-size: 2.25rem;
  line-height: 2.65rem;
  text-transform: uppercase;
  font-weight: 400;
  padding: $main_padding;
  margin-top: 9rem;

  &.h2__big {
    text-transform: capitalize;
    font-size: 6.3rem;
    line-height: 7.55rem;
    margin: 5rem auto;
    font-family: $font_medium;
  }

  & + hr {
    margin-top: 1.2rem;
    margin-bottom: 4rem;
  }
}

h3 {
  font-size: 2.15rem;
  line-height: 2.65rem;
  font-family: $font_bold;
}

h4 {
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-family: $font_medium;
  margin-bottom: 1.2rem;
  font-weight: 500;
}

h5 {
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-family: $font_light;
  font-weight: 400;
}

.p_0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-5 {
  margin-top: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.main__padding {
  padding: $main_padding;
}

main {
  margin-top: 7.35rem;
}

hr {
  opacity: 0.6;
  border: 0;
  border-color: $brand_darker_color;
  border-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  margin: 4rem 0;
}

.main__btn {
  margin: 4rem auto 6rem;
  max-width: 90rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: $font_bold;
  font-size: 1.8rem;
  line-height: 2rem;
  color: $brand_color;
  background-color: $white_color;
  border: 1px solid $brand_color;
  border-radius: 500rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;

  &:hover {
    color: $white_color;
    background-color: $brand_color;
    border-color: $brand_color;
  }

  &--dark {
    color: $white_color;
    background-color: $brand_color;
    &:hover {
      color: $brand_color;
      background-color: $white_color;
    }
  }

  &--arrow {
    padding-right: 3.9rem;
    &::after {
      position: relative;
      content: '';
      background-image: url(../img/icons/arrow-right.svg);
      background-size: cover;
      background-repeat: no-repeat;
      display: block;
      width: 1rem;
      height: 1rem;
      right: -0.9rem;
      filter: none;
    }

    &:hover::after {
      filter: brightness(0) invert(1);
    }
  }
}

.main__small_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font_medium;
  font-size: 1.2rem;
  padding: 1.35rem 3.6rem;
  color: $white_color;
  background-color: $brand_color;
  border: 1px solid $brand_color;
  border-radius: 500rem;
  transition-property: background-color, color, fill, stroke, opacity,
    box-shadow, transform;
  transition-duration: 200ms;
  position: relative;

  &--arrow {
    padding-right: 3.9rem;
    &::after {
      position: relative;
      content: '';
      background-image: url(../img/icons/arrow-right.svg);
      background-size: cover;
      background-repeat: no-repeat;
      display: block;
      width: 1rem;
      height: 1rem;
      right: -0.9rem;
      filter: brightness(0) invert(1);
    }

    &:hover::after {
      filter: none;
    }
  }

  &:hover {
    color: $brand_color;
    background-color: $white_color;
  }

  &--white {
    color: $brand_color;
    background-color: $white_color;
    border-color: $white_color;

    &:hover {
      color: $white_color;
      background-color: $brand_color;
      border-color: $white_color;
    }
  }
}

.img__hover__transform {
  overflow: hidden;
  border-radius: 1.2rem;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease-in-out;
    border-radius: 1.2rem;
  }
  &:hover {
    img {
      transform: scale(1.04);
      border-radius: 1.2rem;
    }
  }
}

.line {
  width: 100%;
  padding: 2.2rem 0.75rem;
  margin: 6rem 0;
  background-color: $background_white_color--third;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 1.6rem;
    color: $brand_darker_color;
    font-family: $font_bold;
  }
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.radio {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  // &--small {
  //   grid-template-columns: repeat(3, minmax(16rem, 11rem));
  // }

  label {
    cursor: pointer;

    span {
      display: block;
      font-size: 1.1rem;
    }

    input {
      cursor: none;
    }
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
    cursor: none;
  }
  [type='radio']:checked + span,
  [type='radio']:not(:checked) + span {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $brand_color;
    height: 1.65rem;
    margin-top: 0.5rem;
    margin-left: 0.6rem;
  }
  [type='radio']:checked + span span:before,
  [type='radio']:not(:checked) + span span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.1rem;
    width: 1.1rem;
    height: 1.1rem;
    border: 1px solid $brand_color;
    border-radius: 100%;
  }
  [type='radio']:checked + span span:after,
  [type='radio']:not(:checked) + span span:after {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    background: $brand_color;
    position: absolute;
    top: 0.41rem;
    left: 0.3rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + span span:after {
    opacity: 0;
  }
  [type='radio']:checked + span span:after {
    opacity: 1;
  }
  [type='radio']:checked + .wpcf7-list-item-label:before,
  [type='radio']:not(:checked) + .wpcf7-list-item-label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.1rem;
    width: 1.1rem;
    height: 1.1rem;
    border: 1px solid $brand_color;
    border-radius: 100%;
  }
  [type='radio']:checked + .wpcf7-list-item-label:after,
  [type='radio']:not(:checked) + .wpcf7-list-item-label:after {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    background: $brand_color;
    position: absolute;
    top: 0.41rem;
    left: 0.3rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + .wpcf7-list-item-label:after {
    opacity: 0;
  }
  [type='radio']:checked + .wpcf7-list-item-label:after {
    opacity: 1;
  }
}

.wpcf7-not-valid-tip {
  display: none !important;
}

.wpcf7 form .wpcf7-response-output {
  display: none !important;
}

.form__group__btn {
  position: relative;
}

form {
  .wpcf7-spinner {
    position: absolute !important;
    top: 43%;
    right: 3%;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 1.25rem;
  } 
  .wpcf7-list-item {
    margin: 0;
  }
  .wpcf7-radio {
    display: flex;
    gap: 1rem;
  }
}

