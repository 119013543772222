.steps {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 2rem;
  margin-top: 2rem;

  &__item {
    display: grid;
    grid-gap: 2rem;
    grid-template:
      'text text'
      'leftImage rightImage';

    grid-template-rows: auto 29rem;
    grid-template-columns: 1fr 1fr;

    &__text {
      grid-area: text;
      padding: 3rem;
      border-radius: 1.2rem;
      background-color: $background_white_color--third;

      span {
        display: inline-block;
        text-align: right;
        width: 100%;
        font-size: 2.25rem;
        margin-bottom: 3.65rem;
        color: $light_color--second;
      }

      p {
        font-size: 2.25rem;
        line-height: 3.15rem;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.2rem;
      &--left {
        grid-area: leftImage;
      }
      &--right {
        grid-area: rightImage;
      }
    }
  }
}
