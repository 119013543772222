.calculator_carousel {
  background-color: $background_white_color--third;
  &__img {
    border-radius: 1.2rem;
    margin: 0.8rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.2rem;
    }
  }
}
